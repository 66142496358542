import cn from 'clsx';
import React, { useRef, useEffect } from 'react';
import { IconElement, renderIcon } from '../utils/reactUtils';
import { useRipple } from '../utils/ripple';

export interface TabProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'type'> {
  icon?: IconElement;
  label?: string;
  active?: boolean;
  stacked?: boolean;
  minWidth?: boolean;
}

export function Tab({ label, icon, active, stacked, minWidth, ...props }: TabProps) {
  const tabRef = useRef<HTMLButtonElement>(null);
  const ripRef = useRipple();

  useEffect(() => {
    if (active)
      requestAnimationFrame(() => {
        tabRef.current!.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
      });
  }, [active]);
  return (
    <button
      ref={tabRef}
      className={cn('mdc-tab', {
        'mdc-tab--active': active,
        'mdc-tab--stacked': stacked,
        'mdc-tab--min-width': minWidth,
      })}
      role="tab"
      aria-selected={active}
      tabIndex={0}
      type="button"
      {...props}
    >
      <span className="mdc-tab__content">
        {icon && renderIcon(icon, 'mdc-tab__icon')}
        <span className="mdc-tab__text-label">{label}</span>
      </span>
      <span className={cn('mdc-tab-indicator', { 'mdc-tab-indicator--active': active })}>
        <span className="mdc-tab-indicator__content mdc-tab-indicator__content--underline" />
      </span>
      <span className="mdc-tab__ripple" ref={ripRef} />
    </button>
  );
}

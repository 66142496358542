import React from 'react';
import cn from 'clsx';

export interface TabBarProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  align?: 'start' | 'end' | 'center';
}

export function TabBar({ children, align, className, ...props }: TabBarProps) {
  return (
    <div
      className={cn('mdc-tab-bar', { [`mdc-tab-bar--align-${align}`]: align }, className)}
      ref={attachListener}
      role="tablist"
      {...props}
    >
      {children}
    </div>
  );
}

function attachListener(r: HTMLDivElement | null) {
  if (r) r.addEventListener('wheel', scrollTabs);
}
function scrollTabs(e: WheelEvent) {
  const target = e.currentTarget as HTMLDivElement;
  if (target!.scrollWidth > target.offsetWidth) {
    e.preventDefault();
    target.scrollBy({ left: e.deltaY, behavior: 'auto' });
  }
}
